@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

html,body {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
h1,
h2,
h3,
h4,
h5
 {
  padding: 0;
  margin: 0;
}
#root {
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* min-width: 1200px;
  overflow: auto; */
}
* {
  box-sizing: border-box;
}
*, :focus {
  outline: 0;
}
.bench-input-item .ant-form-item-control-input-content {
  display: flex;
  justify-content: center;
  gap: 5px;
}
.bench-input-item .ant-form-item-explain-error {
  margin-left: 4%;
}

/* 拖拽组件样式 */
div[data-rbd-droppable-id='droppable-sort-list'] {
  display: flex;
  gap: 10px;
}