.login-layout {
  height: 100%;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: center;
}
.logo-bg {
  width: 836px;
  position: relative;
  height: 100%;
  min-height: 600px;
  overflow: hidden;
  background-image: url('@/assets/login/login_bg.png');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.logo-bg-img {
  height: 100%;
  object-fit: cover;
}
.logo {
  width: 108px;
  position: absolute;
  top:30px;
  left: 40px;
}
.logo-content {
  flex:1
}
.contain {
  width: 400px;
  min-height: 500px;
  padding: 30px 46px;
  background: #fff;
  position: relative;
}
.box-shadow {
  /* border: 1px solid #333; */
  border-radius: 30px;
  box-shadow: 0px 0px 20px 5px #e9e9e9;
}
@media screen and (max-width:1440px) {
  .logo-bg {
    width: 500px; 
  }
  .contain {
    /* width: 400px; */
    height: 400px;
  }
}
.code-item {
  display: flex;
  justify-content: space-between;
}
.code-btn {
  width: 120px;
  margin-left: 10px;
}
.code-input {
  flex: 1;
}
.config-block-btn {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  width: 100%;
  border: 1px solid #e7e7e7;
  background-color: #cfe9f2;
  margin-bottom: 20px;
  box-sizing: border-box;
  cursor: pointer;
}
.config-block-btn:hover {
  border: 1px solid #89dcf5;
}
.block-left {
  height: 60px;
  width: 60px;
  border: 1px solid #888;
  margin-right: 10px;
}
.block-right {
  flex: 1;
}
.block-right>p {
  color: #444;
  font-size: 10px;
  text-align: left;
}
.login-title {
  font-family: Alibaba PuHuiTi;
  font-size: 30px;
  font-weight: 800;
  line-height: 45px;
  background: linear-gradient(to right, #476FE8, #7EA5FF);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.login-sub-title {
  font-family: Alibaba PuHuiTi;
  font-size: 22px;
  font-weight: 500;
  line-height: 36px;
  margin: 0px 0 40px 0;
}
.password-top {
  margin-bottom: 40px;
}
.password-top h2 {
  line-height: 30px;
  margin: 5px 0;
}
.password-tip {
  font-family: PingFang HK;
  font-size: 12px;
  font-weight: 500;
  line-height: 25px;
  color: #999;
}
.password-back {
  display: inline-block;
  font-size: 14px;
  cursor: pointer;
  margin-bottom: 10px;
}
.password-back img {
  width: 22px;
  vertical-align: middle;
}