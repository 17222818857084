.layout {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  
}
.container {
  /* border: 1px solid #333; */
  flex: 1;
  overflow: auto;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #999;
  padding: 10px;
  box-sizing: border-box;
}
.left {
  display: flex;
  align-items: center;
  gap: 100px;
}
.middle {
  font-size: 14px;
  font-weight: 500;
  color: #222;
}
.m-item {
  padding: 0 20px;
  cursor: pointer;
}
.m-item:hover,.m-item.actived {
  color: #1677ff;
}